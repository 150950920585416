<template>
    <div :class="$vuetify.breakpoint.xs ? 'mx-4 mt-4' : 'mx-8 mt-8'">
        <DataTable tableClass="elevation-1" :headers="headers" :items="fares">
            <template #top>
                <v-container fluid>
                    <v-row>
                        <v-col class="d-flex"
                            ><v-toolbar-title>{{
                                $vuetify.breakpoint.smAndUp
                                    ? "Lista de gestion de costos"
                                    : "Lista de costos"
                            }}</v-toolbar-title>
                            <v-divider vertical class="ml-5"></v-divider
                        ></v-col>
                        <v-spacer></v-spacer>
                        <v-col class="d-flex justify-end">
                            <Dialog
                                v-model="createDialog.showDialog"
                                width="800"
                            >
                                <template #activator="{ on, attrs }">
                                    <Button
                                        text="Nuevo"
                                        color="primary"
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                    />
                                </template>
                                <Card
                                    :fields="fields"
                                    @cancel="cancelDialog(createDialog)"
                                    acceptText="Crear"
                                    @accept="postFare"
                                    title="Crear costo"
                                >
                                    <template #content="{ item }">
                                        <TextField
                                            :prefix="item.prefix"
                                            v-model.number="item.model"
                                            :label="item.label"
                                            :type="item.type"
                                            :maxlength="
                                                item.prefix === '$' && 9
                                            "
                                        />
                                    </template>
                                </Card>
                            </Dialog>
                        </v-col>
                    </v-row>
                </v-container>
                <div v-for="(snackbar, index) in snackbars" :key="index">
                    <Snackbar
                        v-model="snackbar.display"
                        :color="snackbar.color"
                        top
                        right
                        class="pt-15"
                    >
                        <span class="color-negro-1 f-body-2 mulish">
                            {{ snackbar.text }}
                        </span>
                        <template v-slot:action="{ attrs }">
                            <Icon
                                v-bind="attrs"
                                color="black"
                                icon="mdi-close"
                                @click="snackbar.display = false"
                            />
                        </template>
                    </Snackbar>
                </div>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
                {{ transformDate(item.created_at, "LL") }}
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
                {{ transformDate(item.updated_at, "LL") }}
            </template>
            <template v-slot:[`item.value`]="{ item }">
                {{ "$" + item.value }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <Icon
                    small
                    icon="mdi-pencil"
                    class="mr-2"
                    @click="openUpdateDialog(item)"
                />
                <Dialog v-model="item.dialog" grid width="800">
                    <Card
                        @cancel="item.dialog = false"
                        @accept="updateFare(item.id, item)"
                    >
                        <template #updateContent>
                            <v-container fluid>
                                <v-row class="px-4">
                                    <v-col cols="12" sm="6">
                                        <TextField
                                            v-model.number="updateCopy.name"
                                            label="Costo"
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <TextField
                                            prefix="$"
                                            v-model="updateCopy.value"
                                            label="Precio"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </template>
                    </Card>
                </Dialog>
                <Dialog v-model="item.updateDialog" width="800" persistent>
                    <v-card flat>
                        <v-card-title>Actualizar productos</v-card-title>
                        <v-card-subtitle
                            class="pt-2"
                            v-if="productsByCost.length > 0"
                            >Se actualizaran
                            {{ productsByCost.length }} productos que utilizen
                            este costo</v-card-subtitle
                        >
                        <v-card-text v-if="productsByCost.length > 0">{{
                            count + "/" + productsByCost.length
                        }}</v-card-text>
                        <div v-if="productsByCost.length !== 0" class="pa-4">
                            <ul class="pa-0 pl-2">
                                <div
                                    class="d-flex justify-space-between align-start"
                                    v-for="(p, index) in productsByCost"
                                    :key="index"
                                >
                                    <p>
                                        {{ p.name }}
                                    </p>
                                    <p>{{ "Minorista: $" + p.price }}</p>
                                    <p>
                                        {{ "Mayorista: $" + p.wholesale_price }}
                                    </p>

                                    <div
                                        :class="['circle', p.class]"
                                        :title="
                                            p.class === 'grey'
                                                ? 'Pendiente'
                                                : p.class === 'primary'
                                                ? 'Actualizando'
                                                : p.class === 'success'
                                                ? 'Actualizado'
                                                : 'Fallido'
                                        "
                                    ></div>
                                </div>
                            </ul>
                        </div>
                        <div v-else class="pa-6 text-center">
                            <h2>Este costo no tiene productos asociados</h2>
                        </div>
                        <v-card-actions
                            v-if="showCancelButton"
                            class="d-flex justify-end"
                        >
                            <Button
                                text="Aceptar"
                                color="primary"
                                @click="aceptChanges"
                            />
                        </v-card-actions>
                    </v-card>
                </Dialog>
                <!-- <Icon
                    small
                    icon="mdi-delete"
                    @click="item.deleteDialog = true"
                />
                <Dialog v-model="item.deleteDialog" width="500">
                    <Card
                        :grid="false"
                        acceptText="Eliminar"
                        title="Seguro que desea eliminar este costo?"
                        @cancel="item.deleteDialog = false"
                    />
                </Dialog> -->
            </template>
        </DataTable>
    </div>
</template>

<script>
import DataTable from "@/components/vuetify/DataTable";
import Button from "@/components/vuetify/Button";
import Icon from "@/components/vuetify/Icon.vue";
import Dialog from "@/components/vuetify/Dialog.vue";
import TextField from "@/components/vuetify/TextField.vue";
import Select from "@/components/vuetify/Select.vue";
import Card from "@/components/vuetify/Card.vue";
import Snackbar from "@/components/vuetify/Snackbar.vue";
import { getFares, createFares, updateFares } from "@/services/fares.js";
import { updateProducts, putProductInWordpress } from "@/services/wholesale.js";
import { getProductsBySpecificFare } from "@/services/products.js";
import { transformDate } from "@/helpers/dateHelper";
import { mapGetters, mapActions, mapState } from "vuex";
import { EventBus } from "@/Event-bus.js";

export default {
    components: {
        DataTable,
        Button,
        Icon,
        Dialog,
        TextField,
        Card,
        Select,
        Snackbar,
    },
    data() {
        return {
            transformDate,
            search: "",
            count: 0,
            showCancelButton: false,
            updateCopy: null,
            productsByCost: [],
            fares: [],
            fields: [
                {
                    model: "",
                    label: "Costo",
                    placeholder: "",
                    type: "text",
                    md: "6",
                },
                {
                    model: "",
                    label: "Precio",
                    placeholder: "",
                    prefix: "$",
                    md: "6",
                },
            ],
            snackbars: [
                {
                    display: false,
                    text: "",
                    color: "",
                },
                {
                    display: false,
                    text: "",
                    color: "",
                },
            ],
            createDialog: { showDialog: false },
            deleteDialog: { showDialog: false },
            dialog: false,
            headers: [
                {
                    text: "ID",
                    value: "id",
                },
                {
                    text: "Nombre del costo",
                    value: "name",
                },
                {
                    text: "Precio del costo",
                    value: "value",
                },
                {
                    text: "Fecha de creacion",
                    value: "created_at",
                },
                {
                    text: "Fecha de actualizacion",
                    value: "updated_at",
                },
                {
                    text: "",
                    value: "actions",
                },
            ],
        };
    },
    computed: {
        ...mapState(["userToken", "textSearch"]),
        ...mapGetters(["getTextSearch"]),
    },
    methods: {
        ...mapActions(["handleLoading"]),
        cancelDialog(dialog) {
            dialog.showDialog = false;
        },
        async aceptChanges(item) {
            item.updateDialog = false;
            this.count = 0;
            await this.getFares();
        },
        async postFare() {
            try {
                this.handleLoading(true);
                let transformedData = {
                    name: this.fields[0].model,
                    value: this.fields[1].model,
                };
                const response = await createFares(
                    transformedData,
                    this.userToken
                );
                if (response.status === 200 || response.status === 201) {
                    await this.getFares();
                    this.cancelDialog(this.createDialog);
                    this.fields[0].model = "";
                    this.fields[1].model = "";
                    this.snackbars[0].display = true;
                    this.snackbars[0].text =
                        "El costo ha sido creado con exito";
                    this.snackbars[0].color = "green";
                }
            } catch (error) {
                this.snackbars[0].display = true;
                this.snackbars[0].color = "red";
                this.snackbars[0].text = "Error al crear el costo";
                console.log(error);
            } finally {
                this.handleLoading(false);
                this.cancelDialog(this.createDialog);
            }
        },
        calculatePercentage(index, percentage, wholesale_percentage, context) {
            if (context.length === 0) return;
            context[index].price = Math.floor(
                context[index].totalCost +
                    (percentage * context[index].totalCost) / 100
            );
            context[index].wholesale_price = Math.floor(
                context[index].price -
                    (wholesale_percentage * context[index].price) / 100
            );
        },
        async updateProductsByFare(context, index, params) {
            try {
                params.updateDialog = true;
                this.handleLoading(true);
                const response = await updateProducts(
                    context[index].id,
                    context[index],
                    this.userToken
                );
                if (response.status === 200 || response.status === 201) {
                    try {
                        this.handleLoading(true);
                        let id = parseInt(context[index].id_wc);
                        let dataToSend = {
                            regular_price: context[index].price.toString(),
                            meta_data: [
                                {
                                    key: "b2bking_regular_product_price_group_51283",
                                    value: context[
                                        index
                                    ].wholesale_price.toString(),
                                },
                            ],
                        };
                        const res = await putProductInWordpress(id, dataToSend);
                        if (res.status === 200 || res.status === 201) {
                            this.snackbars[0].display = true;
                            this.snackbars[0].text =
                                "Los precios se han conectado exitosamente";
                            this.snackbars[0].color = "green";
                        }
                    } catch (error) {
                        console.log(error);

                        if (error.status === 404) {
                            this.snackbars[0].display = true;
                            this.snackbars[0].color = "red";
                            this.snackbars[0].text =
                                "No se ha encontrado el producto con ese ID";
                        }

                        this.snackbars[0].display = true;
                        this.snackbars[0].color = "red";
                        this.snackbars[0].text = "Error al vincular producto";
                    } finally {
                        this.handleLoading(false);
                    }
                    this.handleLoading(false);
                    this.snackbars[0].display = true;
                    this.snackbars[0].color = "green";
                    this.snackbars[0].text =
                        "Los precios han sido modificados con exito";
                }
            } catch (error) {
                if (error) {
                    this.snackbars[0].display = true;
                    this.snackbars[0].color = "red";
                    this.snackbars[0].text =
                        "Los precios no han podido ser modificados o vinculados";
                }
                console.log(error);
            } finally {
                this.handleLoading(false);
            }
        },

        async getProductsByFare(id, params) {
            try {
                this.handleLoading(true);
                params.updateDialog = true;
                const resByCost = await getProductsBySpecificFare(
                    id,
                    this.userToken
                );
                if (resByCost.status === 200) {
                    this.productsByCost = resByCost.data.data;
                    this.productsByCost.forEach((product, index) => {
                        product.class = "grey";
                        const costToUpdate = product.costs.find(
                            (cost) => cost.id_cost === id
                        );
                        if (costToUpdate) {
                            product.class = "primary";
                            costToUpdate.cost_value = this.updateCopy.value;
                            product.totalCost = product.costs.reduce(
                                (acc, cost) => {
                                    return acc + parseFloat(cost.cost_value);
                                },
                                0
                            );
                            this.calculatePercentage(
                                index,
                                parseInt(product.percentage),
                                parseInt(product.wholesale_percentage),
                                this.productsByCost
                            );
                            this.updateProductsByFare(
                                this.productsByCost,
                                index,
                                params
                            );
                            product.class = "success";
                            this.count++;
                        } else {
                            product.class = "error";
                        }
                    });
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.handleLoading(false);
                this.showCancelButton = true;
            }
        },
        async updateFare(id, params) {
            try {
                this.handleLoading(true);
                let updatedFare = {
                    name: this.updateCopy.name,
                    value: this.updateCopy.value,
                };

                const response = await updateFares(
                    id,
                    updatedFare,
                    this.userToken
                );
                if (response.status === 200 || response.status === 201) {
                    params.dialog = false;
                    params.updateDialog = true;
                    this.handleLoading(false);

                    this.snackbars[0].display = true;
                    this.snackbars[0].color = "green";
                    this.snackbars[0].text =
                        "El costo ha sido modificado con exito";
                    await this.getProductsByFare(id, params);
                }
            } catch (error) {
                if (error) {
                    params.dialog = false;

                    this.snackbars[0].display = true;
                    this.snackbars[0].color = "red";
                    this.snackbars[0].text =
                        "El costo no ha podido ser modificado";
                }
                console.log(error);
            } finally {
                this.handleLoading(false);
            }
        },
        async getFares(q) {
            try {
                this.handleLoading(true);
                const response = await getFares(this.userToken, q);
                if (response.status === 200) {
                    this.fares = response.data.map((customer) => ({
                        ...customer,
                        dialog: false,
                        updateDialog: false,
                        deleteDialog: false,
                    }));
                }
            } catch (error) {
                console.log(error);
            } finally {
                this.handleLoading(false);
            }
        },
        openUpdateDialog(item) {
            const itemCopy = { ...item };
            this.updateCopy = itemCopy;
            item.dialog = true;
        },
    },
    async created() {
        this.search = this.getTextSearch;
        EventBus.$on("doSearch", async () => {
            this.search = this.getTextSearch;
            this.handleLoading(true);
            await this.getFares(this.search);
            this.handleLoading(false);
        });
        await this.getFares();
    },
};
</script>
<style scoped lang="scss">
.circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}
</style>
